
html{height:100vh;padding:env(safe-area-inset)}

.InteractiveVideo_interactive-video__Ej1qP {
  align-items: center;
  display: flex;
  flex: 1;
  height: 100%;
  height: -webkit-fill-available;
  justify-content: center;
  position: relative;
  width: 100%;
  z-index: 2;
}
.InteractiveVideo_interactive-video__Ej1qP #editTextButton,
.InteractiveVideo_interactive-video__Ej1qP #hiddenInput {
  display: none;
}
.InteractiveVideo_interactive-video__Ej1qP video::-webkit-media-controls {
  pointer-events: none;
}
.css-fe8aaf {
  z-index: 3;
  align-self: baseline;
  position: absolute;
  inset: auto 10px 10px auto;
  min-width: 0px !important;
}


.btnVolume{
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  outline: 0px;
  border: 0px;
  margin: 0px 10px 0px 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-weight: 600;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
  font-size: 0.8125rem;
  line-height: 1.75;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(34, 43, 54);
  box-shadow: none;
  text-transform: none;
  background: rgba(0, 0, 0, 0.6);
  z-index: 3;
  min-width: 0px;
  padding: 3px;
  width: 32px;
  height: 32px;
  border-radius: 7px;
}


.btnFullScreen{
  display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 600;
    font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 0.8125rem;
    line-height: 1.75;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: rgb(34, 43, 54);
    box-shadow: none;
    text-transform: none;
    background: rgba(0, 0, 0, 0.6);
    z-index: 9;
    min-width: 0px;
    padding: 3px;
    width: 32px;
    height: 32px;
    border-radius: 7px;

}

.app_app__9OAH2 {
  background: #000;
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  height: 100%;
  height: -webkit-fill-available;
  justify-content: center;
  position: relative;
  position: fixed;
  width: 100%;
}
body {
  margin: 0;
}

video::-webkit-media-controls-enclosure {
  display:none !important;
}